import React, { useState, useEffect } from "react";
import craftConfig from "@craftConfig";
import * as DynamicQueries from "@dynamicQueries";
import getParam from "@utils/getParam";
import { navigate } from "gatsby";
import usePreviewQueries from "@staticQueries/PreviewQueriesQuery";

const Preview = ({ ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [query, setQuery] = useState(null);

  const queries = usePreviewQueries();

  useEffect(() => {
    const type = getParam("type");
    const section = getParam("section");
    const token = getParam("token");
    const uri = getParam("uri");
    if (!token && uri) navigate(`/${uri}`);
    const page = craftConfig.pages.find(
      p => p.type === type && p.section === section
    );
    setQuery(page.query);
    setLoaded(true);
  }, []);

  const Page = DynamicQueries[`${query}Query`]
    ? DynamicQueries[`${query}Query`]
    : null;

  const previewQuery = queries[`${query}Query`];

  if (!loaded)
    return (
      <div className="flex flex-grow items-center justify-center">
        Preview loading...
      </div>
    );

  if (Page) {
    return <Page {...props} pageContext={{ previewQuery }} />;
  }

  return (
    <div className="flex flex-grow items-center justify-center">
      Preview could not be matched to any existing templates.
    </div>
  );
};

export default Preview;

// ! make sure to set the preview target to {alias('@frontend')}/preview?type={type.handle}&section={section.handle}&uri={uri}&site={site.id}
// ! also be sure to set reload to false

module.exports = {
  api: {
    url: process.env.GATSBY_CRAFT_API_URL,
    // preview token (exposed to client)
    token: process.env.GATSBY_CRAFT_API_TOKEN,
  },
  pages: [
    { section: "advantage", type: "advantage", query: "Advantage" },
    { section: "careers", type: "careers", query: "Careers" },
    {
      section: "caseStudiesIndex",
      type: "caseStudiesIndex",
      query: "CaseStudiesIndex",
    },
    { section: "caseStudies", type: "caseStudy", query: "CaseStudies" },
    { section: "company", type: "company", query: "Company" },
    {
      section: "digitrakDatalogManagement",
      type: "digitrakDatalogManagement",
      query: "DigitrakDatalogManagement",
    },
    { section: "displaysIndex", type: "displays", query: "DisplaysIndex" },
    { section: "displays", type: "displays", query: "Displays" },
    { section: "eventsIndex", type: "eventsIndex", query: "EventsIndex" },
    { section: "events", type: "event", query: "Events" },
    {
      section: "fieldSupport",
      type: "fieldSupport",
      query: "FieldSupport",
    },
    { section: "findADealer", type: "findADealer", query: "FindADealer" },
    { section: "homepage", type: "homepage", query: "Homepage" },
    {
      section: "landingPages",
      type: "landingPages",
      query: "LandingPages",
    },
    {
      section: "logWhileDrilling",
      type: "logWhileDrilling",
      query: "LogWhileDrilling",
    },
    {
      section: "receiversIndex",
      type: "receiversIndex",
      query: "ReceiversIndex",
    },
    { section: "receivers", type: "receiver", query: "Receivers" },
    { section: "technology", type: "technology", query: "Technology" },
    { section: "teratrak", type: "teratrak", query: "Teratrak" },
    {
      section: "transmittersIndex",
      type: "transmitters",
      query: "TransmittersIndex",
    },
    {
      section: "transmittersPage",
      type: "transmitter",
      query: "TransmittersPage",
    },
  ],
};

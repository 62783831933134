import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query PreviewsQueries {
    allPreviewQuery {
      nodes {
        handle
        query
      }
    }
  }
`;

const dataResolver = ({ allPreviewQuery: { nodes } }) => {
  return nodes.reduce((agg, node) => {
    // eslint-disable-next-line no-param-reassign
    agg[node.handle] = node.query;
    return agg;
  }, {});
};
const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
